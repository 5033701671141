import { Badge } from '@/components/badge';

const badges = [
    {
        color: 'orange',
        initialPosition: {
            rotate: 14,
            x: '40%',
            y: '78%',
        },
        text: '#WorkSmart',
    },
    {
        color: 'green',
        initialPosition: {
            rotate: -8,
            x: '20%',
            y: '30%',
        },
        text: '#GotYourBack',
    },
    {
        color: 'pink',
        initialPosition: {
            rotate: 8,
            x: '100%',
            y: '15%',
        },
        text: '#BeKind',
    },
    {
        color: 'violet',
        initialPosition: {
            rotate: -18,
            x: '80%',
            y: '65%',
        },
        text: '#BeProud',
    },
    {
        color: 'yellow',
        initialPosition: {
            rotate: 6,
            x: '60%',
            y: 0,
        },
        text: '#SpreadGoodVibes',
    },
] as const;

export const FloatingBadges = () => (
    <div
        className={`
          relative min-h-[130px] w-[90%] justify-self-end

          lg:max-w-[475px]

          xl:-right-20
        `}
    >
        {badges.map(({ color, initialPosition, text }) => (
            <Badge
                key={color}
                color={color}
                className="top-1/2"
                style={{
                    right: initialPosition.x,
                    top: initialPosition.y,
                }}
                typingEffectConfig={{
                    maxSpeed: 0,
                    minSpeed: 0,
                }}
                movementConfig={{
                    disableInitialRandomOffset: true,
                    distance: '100px',
                    initialAngle: initialPosition.rotate,
                }}
                size="md"
            >
                {text}
            </Badge>
        ))}
    </div>
);
